@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }

.about__skills{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
    
   
    div{
        
        margin:1rem;
        border-radius: 50%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        

        img{
            width: 100%;
            height: 100%;
            padding: 0.7rem;
            border-radius: 30%;
    
        }

        div{
            position: absolute;
            width: 150%;
            height: 150%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 25px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            color: #fff;
            cursor:default;
            border: 1px solid black;

            &:hover{
                opacity: 1;
                background: rgba($color: #2B2D42, $alpha: 0.8)
            }
        }

        
    }
}

.skillDiv{
    box-shadow:  0 0 0 9px rgba(255, 255, 255, 0.3);
    --time-duration: 2s;
    --border1: 60%;
    --border2: 40%;
    --border3: 30%;
    --border4: 70%;
    
    
    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {

           --time-duration: #{random_range(4s, 8s)};
            --border1: #{random_range(20%, 80%)};
            --border2: #{random_range(30%, 60%)};
            --border3: #{random_range(30%, 60%)};
            --border4: #{random_range(30%, 70%)};
            --size: random_range(60%, 100%)
            
        }
        
      }

    animation: skill__animate var(--time-duration) ease-in-out infinite;
    

    @keyframes skill__animate {
        0%{
          border-radius: var(--border1) var(--border2) 30% 70%/var(--border1) 30% 70% var(--border2);
        }
        50%{
          border-radius: var(--border3) var(--border2) var(--border4) var(--border1)/50% var(--border4) var(--border3) 60%;
        }
        100%{
          border-radius: var(--border1) var(--border2) 30% 70%/var(--border1) 30% 70% var(--border2);
        }
        
      }
  }
  
  
  
  
  
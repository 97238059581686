.app__works{
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-direction: column;
    position: relative;

    h2{
        font-family: var(--font-title);
        color: #fff;
        font-size: 48px;
        margin-bottom: 3rem;
    }

    

    @media screen and (max-width: 400px){
        h2{
            font-size: 36px;
            margin-bottom: 2rem;
        }
    }
}

.app__works-container{
    position: relative;
    width: 100%;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: row;
}

.works__work{
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{
        margin-top: 1rem;
        width: 40%;
        text-align: center;
        color: #fff;
    }
    .works__work-image{
        width: 50%;
        position: relative;

        img{
            width: 100%;
            object-fit: cover;
            
        }
    }

    @media screen and (max-width:1400px) {
        .works__work-image{
            width: 70%;
        }

        p{
            width: 60%;
        }
    }

    @media screen and (max-width:600px) {
        .works__work-image{
            width: 80%;
        }

        p{
            width: 90%;
        }
    }
}



.works__arrows{
    position: absolute;
    font-size: 40px;
    width: 50px;
    height: 50px;
    color: white;
    border-radius: 50%;
    cursor: pointer;
}

.works__arrow-icon-left{
    left:5%;
}

.works__arrow-icon-right{
    right: 5%;
}

@media screen and (max-width: 600px){

    .works__arrows{
        width: 30px;
        height: 30px;
    }
    .works__arrow-icon-left{
        left:2%;
    }
    
    .works__arrow-icon-right{
        right: 2%;
    }
}

@media screen and (max-width: 450px){

    
    .works__arrow-icon-left{
        left:0;
    }
    
    .works__arrow-icon-right{
        right: 0;
    }
}

@media screen and (max-width: 350px){

    
    .works__arrow-icon-left{
        left:-5%;
    }
    
    .works__arrow-icon-right{
        right: -5%;
    }
}